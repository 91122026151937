import { Observable } from 'rxjs';
import { Observer } from 'rxjs';

declare const window: any;
window.singleSpaAngularCli = window.singleSpaAngularCli || {};

export class Platform {
    name: string;
    router: any;

    mount(name: string, router?: any): Observable<any> {
        this.name = name;
        this.router = router;
        return Observable.create((observer: Observer<any>) => {
            if (this.isSingleSpaApp()) {
                window.singleSpaAngularCli[this.name] = window.singleSpaAngularCli[this.name] || {};
                window.singleSpaAngularCli[this.name].mount = (props: any): void => {
                    observer.next({ props, attachUnmount: this.unmount.bind(this) });
                    observer.complete();
                };
            }
            else {
                observer.next({ props: {}, attachUnmount: this.unmount.bind(this) });
                observer.complete();
            }
        });
    }

    unmount(module: any): void {
        if (this.isSingleSpaApp()) {
            window.singleSpaAngularCli[this.name].unmount = (): void => {
                if (module) {
                    module.destroy();
                    if (this.router) {
                        module.injector.get(this.router).dispose();
                    }
                }
            };
        }
    }

    private isSingleSpaApp(): boolean {
        return window.singleSpaAngularCli.isSingleSpa;
    }
}
