import 'zone.js';
import { start, registerApplication } from 'single-spa';
import { spaLoader, router } from './single-spa-angular-cli';
import { IApplication } from './common/interfaces';

class SpaLoader {
    public static async loadApps(): Promise<void> {
        // const response = await fetch('/api/apps');
        console.log('portal update 2:27');
        const availableApps: Array<IApplication> = [
            {
                display: 'Market Platform',
                isDefaultApp: true,
                name: 'market',
                hash: '/portal/#/market',
                appURL: APP_URL,
                appPort: null,
                angularCLI: true,
                selector: 'adsp-root'
            }
        ];
        const appListElement = document.getElementById('appList');
        availableApps.forEach((app) => {
            try {
                if (app.angularCLI) {
                    registerApplication(
                        app.name,
                        ((): any => {
                            const lifecycles: any = spaLoader({
                                name: app.name,
                                selector: app.selector,
                                baseHref: app.appURL
                            });
                            return {
                                bootstrap: [ lifecycles.bootstrap ],
                                mount: [ lifecycles.mount ],
                                unmount: [ lifecycles.unmount ],
                                unload: [ lifecycles.unload ]
                            };
                        })(),
                        router.matchRoute(app.hash, app.isDefaultApp)
                    );
                }
                else {
                    registerApplication(app.name, () => SystemJS.import(app.appURL), this.hashPrefix(app.hash));
                }
                appListElement.insertAdjacentHTML(
                    'beforeend',
                    `<a href="portal/#/${app.name}">
                        <li>
                            ${app.display}
                        </li>
                    </a>`
                );
            } catch (e) {
                console.log(e);
            }
        });
        start();
    }

    public static hashPrefix(prefix: string): any {
        return function(location: any): any {
            return location.hash.startsWith(`#${prefix}`);
        };
    }
}

SpaLoader.loadApps();
